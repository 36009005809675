import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DocumentsService } from 'src/app/shared/services/documents.service';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  title = 'Mi perfil';
  docsList = [];
  docsGroupByArea = [];
  isLoading = true;
  noDocs = false;
  isSpecMode = false;

  selectedTab = new UntypedFormControl(0);
  panelOpenState = true;

  public user$;
  public user;

  docIdentifyer = (index:number, item: any) => item.key;

  allowedIds = [
    '227577',
    '225618',
    '227645',
    '228786',
    '229562',
    '228289',
    '227958',
    '228321',
    '225200',
    '227974',
    '229651',
    '229654',
    '229681',
    '229722',
    '229800',
    '228375',
    '228785',
    '228217',
    '228581',
    '228584',
    '215109',
    '219442',
    '220879',
    '227875',
    '229570',
    '228925',
    '228947',
    '228977',
    '229046',
    '229063',
    '229456',
    '229460',
    '227644',
    '223017',
    '227283',
    '130145',
  ];

  constructor(
    private alertService: AlertService,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private specService: SpecViewService,
    private alumnoService: AlumnoService,
    private router: Router,
    private documentsService: DocumentsService
  ) {}

  ngOnInit() {
    this.specService.currentSharedSpecMode.subscribe(data => {this.isSpecMode = data.isSpecMode})
    this.changeDocState = this.changeDocState.bind(this);
    this.getUser();
    this.activeRoute.queryParams.subscribe((params) => {
      if (params.page === 'docs') {
        this.selectedTab.setValue(1);
      } else {
        this.selectedTab.setValue(0);
      }
    });
  }

  getUser() {
    this.alumnoService.getAlumno().subscribe({
      next: (res) => {
        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.email === '' || !res.email || res.email === undefined)
        ) {
          this.alertService.openAlert(
            '¡Sesión vencida!',
            'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
            'Ok'
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }
        this.user = res;
        this.getDocs();
      },
      error: (err) => {
        this.alertService.openAlert(
          '¡Sesión vencida!',
          'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
          'Ok'
        );
        setTimeout(() => {
          this.authService.logout();
        }, 5000);
      },
    });
  }

  getDocs() {
    this.documentsService.getDocuments().subscribe({
      next: (data) => {
        this.docsList = data.data;

        // CHECK AREA
        this.docsList = this.docsList.filter(
          (doc) => doc.area !== '' && doc.area !== null
        );

        // CHECK MODALIDAD
        const modalidades = [];
        this.user.carreras.forEach((carrera) => {
          modalidades.push(carrera.modalidad);
        });

        if (
          modalidades.includes('Presencial') &&
          !modalidades.includes('Online')
        ) {
          this.docsList = this.docsList.filter(
            (doc) =>
              doc.modalidad && (doc.modalidad === 'p' || doc.modalidad === 'b')
          );
        } else if (
          modalidades.includes('Online') &&
          !modalidades.includes('Presencial')
        ) {
          this.docsList = this.docsList.filter(
            (doc) =>
              doc.modalidad && (doc.modalidad === 'o' || doc.modalidad === 'b')
          );
        } else {
          this.docsList = this.docsList.filter((doc) => doc.modalidad);
        }

        // CHECK EXTRANJEROS
        if (
          this.user.escuela_pais.toLowerCase() === 'panama' ||
          this.user.escuela_pais.toLowerCase() === 'panamá'
        ) {
          this.docsList = this.docsList.filter((doc) => !doc.solo_extranjeros);
        }

        // AGRUPACIÓN POR ÁREA
        this.docsGroupByArea = this.docsList.reduce((group, doc) => {
          const { area } = doc;
          group[area] = group[area] ?? [];
          group[area].push(doc);
          return group;
        }, {});
        this.isLoading = false;
        if (this.docsList.length === 0) {
          this.noDocs = true;
        }
      },
      error: (error) => {
        this.noDocs = true;
      },
    });
  }

  changeDocState(docId, state) {
    this.docsList.map((item) => {
      if (item.category_id === docId) {
        item.estado = state;
      }
      return item;
    });
    this.docsGroupByArea = this.docsList.reduce((group, doc) => {
      const { area } = doc;
      group[area] = group[area] ?? [];
      group[area].push(doc);
      return group;
    }, {});
  }

  getPorcentaje(item) {
    return (
      (item.filter((i) => i.estado.toLowerCase() === 'aprobado').length /
        item.length) *
      100
    );
  }

  goTo(tag) {
    this.router.navigate(['/perfil'], { queryParams: { page: tag } });
  }
}
