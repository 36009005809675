<div class="main">
    <div class="modal-header"></div>
    <div class="file-mode-container">
        <mat-progress-bar mode="indeterminate" *ngIf="isUploading"></mat-progress-bar>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="Este dispositivo">
                <div class="local-upload">
                    <img class="img-computer" src="../../../../../assets/images/content/woman_computer.png" alt="">
                    <div class="dote">
                        <h3 class="local-title">Sube el archivo desde tu dispositivo</h3>
                        <input type="file" accept=".jpg, .jpeg, .png, .pdf" (change)="onFileSelected($event, this.userDoc.documentos_adjuntos)" #fileUpload hidden />
                        <button mat-raised-button color="primary" (click)="fileUpload.click()">Seleccionar archivo</button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Codigo QR">
                <div class="qr-upload">
                    <div class="qr-upload-header">
                        <h3 class="qr-title">Scanea el codigo QR para subir el archivo</h3>
                        <div class="qr-contain">
                            <qrcode class="qrcode" [qrdata]="qrString" [width]="250" [errorCorrectionLevel]="'M'"></qrcode>
                        </div>
                        <div class="reload">
                            <p>¿Ya has cargado el archivo?</p> <button mat-raised-button color="primary">Recargar</button>
                        </div>
                    </div>
                </div>
            </mat-tab>
          </mat-tab-group>
    </div>
</div>